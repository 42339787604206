import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 36px;

  @media (max-width: 700px) {
    padding: 20px 10px;
  }

  .error-image {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
`

export const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;

  @media (max-width: 700px) {
    width: 95%;
    padding: 40px 15px;
  }
`

export default Wrapper
