
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { Button } from 'antd'
import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Image from 'next/image'

import Wrapper, { Container } from '../components/UI/ErrorPage/error.style'

function ErrorPage() {
  const router = useRouter()

  return (
    <Wrapper>
      <Container>
        <Head>
          <title>Geçersiz Sayfa</title>
        </Head>
        <div>
          <Image className="error-image" width={700} height={300} src="/images/404/404.png" />
        </div>

        <div style={{ width: '100%', marginTop: 40 }}>
          <h1
            style={{
              marginBottom: 0,
              fontSize: 24,
              color: '#000',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontWeight: '700',
            }}
          >
            Bu sayfa bulunamadı!
          </h1>
        </div>
        <div style={{ marginTop: 20 }}>
          <p style={{ textAlign: 'center', fontSize: 18, fontFamily: 'Poppins' }}>
            Ana sayfaya geri dönebilir, bunun bir hata olduğunu düşünüyorsan bizimle iletişime
            geçebilirsin.
          </p>
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            onClick={() => router.push('/')}
            style={{
              fontSize: 18,
              height: 47,
              fontFamily: 'Poppins',
              fontWeight: '700',
              borderRadius: 40,
              width: 200,
            }}
            type="primary"
          >
            Anasayfaya Git
          </Button>
        </div>
      </Container>
    </Wrapper>
  )
}

export default ErrorPage


    export async function getStaticProps(ctx) {
        
        
        return {
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  